// eslint-disable-next-line no-var, no-underscore-dangle, logical-assignment-operators, no-multi-assign
var _hsq = (globalThis._hsq = globalThis._hsq || []);

export const trackPageChange = (fullPath: string): void => {
  _hsq.push(['setPath', fullPath], ['trackPageView']);
};

export const trackIdentityChange = (email: string): void => {
  _hsq.push(['identify', { email }]);
};
