import { ref, onMounted, onUnmounted, computed } from 'vue';

export enum ScreenSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = '2xl',
}

export const useWindowWidth = () => {
  const windowWidth = ref<number>(0);

  const onWidthChange = () => {
    windowWidth.value = window.innerWidth;
  };

  const isMobileView = computed(() => windowWidth.value < 640);
  const isTabletView = computed(
    () => windowWidth.value >= 640 && windowWidth.value < 1024,
  );
  const isDesktopView = computed(() => windowWidth.value >= 1024);

  const screenSize = computed((): ScreenSize => {
    if (windowWidth.value < 640) return ScreenSize.XS;
    if (windowWidth.value < 768) return ScreenSize.SM;
    if (windowWidth.value < 1024) return ScreenSize.MD;
    if (windowWidth.value < 1280) return ScreenSize.LG;
    if (windowWidth.value < 1536) return ScreenSize.XL;
    return ScreenSize.XXL;
  });

  onMounted(() => {
    windowWidth.value = window.innerWidth;
    window.addEventListener('resize', onWidthChange);
  });

  onUnmounted(() => window.removeEventListener('resize', onWidthChange));

  return {
    windowWidth,
    isMobileView,
    isTabletView,
    isDesktopView,
    screenSize,
  };
};
